.App {
	display: flex;
}

.App > * {
	padding: 20px;
	flex-basis: 50%;
	height: 70vh !important;
	overflow: scroll;
}

.flex {
	display: flex;
	gap: 4px;
	background-color: white;
}

textarea {
	width: 100%;
}

.w-md-editor-content {
	height: 100% !important;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		-webkit-animation: App-logo-spin infinite 20s linear;
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
